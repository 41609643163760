<template>
  <div
    :class="{'disabled': isDisabled}"
    class="upload"
  >
    <h5
      v-if="!isVideo"
      class="step-title"
    >
      <span
        v-if="auth"
        class="step-title__secondary-text"
      >
        {{ $t('services.upload.title') }}
      </span>
    </h5>
    <el-upload
      ref="upload"
      class="upload__container"
      action="#"
      :http-request="httpFunction"
      :auto-upload="true"
      :multiple="false"
      list-type="picture-card"
      :file-list="selectedFile"
      :before-upload="onBeforeUpload"
      :disabled="isDisabled"
      drag
    >
      <div
        v-if="!isDisabled"
        class="drag-container"
      >
        <img
          src="@/assets/images/icons/drag-and-drop.svg"
          alt="dragAndDrop"
        >
        <h5>{{ $t('services.upload.dragAndDrop') }}</h5>
        <button>{{ $t('services.upload.browseImage') }}</button>
        <span>{{
          `${$t('services.upload.imageSizeText')} ${fileSize} MB`
        }}</span>
        <span>{{ $t('services.upload.imageLargeText') }}</span>
      </div>
      <div
        v-else
        class="image-placeholder"
      >
        <img
          :src="placeholderImage"
          alt="Place Holder"
        >
      </div>
      <div
        slot="file"
        slot-scope="{ file }"
        class="slot-file"
      >
        <video
          v-if="isVideo"
          class="video-example"
          :src="`${file.url}#t=0.01`"
          preload="metadata"
          muted
          controls
        />
        <el-image
          v-else
          :src="file.url"
          fit="contain"
          class="image-wrapper"
          lazy
        />
        <span
          v-if="!disabled"
          class="remove-button"
          @click="onRemoveClick"
        ><i
          class="el-icon-delete"
        /></span>
      </div>
    </el-upload>
  </div>
</template>
<script>
import ImagePlaceholder from '@/assets/images/icons/image-placeholder.svg';
import VideoPlaceholder from '@/assets/images/icons/video-placeholder.svg';
import ErrorService from '@/service/utils/error';

export default {
  name: 'Upload',
  props: {
    fileType: {
      type: String,
      default: 'image',
    },
    exampleFile: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    handleRemove: {
      type: Function,
      default: () => {},
    },
    handleUpload: {
      type: Function,
      default: () => {},
    },
    fileSize: {
      type: Number,
      required: true,
    },
    auth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedFile() {
      return this.exampleFile
        ? [
          {
            url: this.exampleFile,
          },
        ]
        : [];
    },
    isVideo() {
      return this.fileType.toLowerCase() === 'video';
    },
    placeholderImage() {
      return this.isVideo ? VideoPlaceholder : ImagePlaceholder;
    },
    isDisabled() {
      return !this.auth || this.isVideo || this.disabled;
    },
  },
  methods: {
    onRemoveClick() {
      if (!this.disabled) {
        this.$refs.upload.clearFiles();
        this.handleRemove();
      }
    },
    onBeforeUpload(file) {
      const isIMAGE = file.type === 'image/jpeg' || 'image/jpg' || 'image/png';
      const isLt = file.size / 1024 / 1024 < this.fileSize;

      if (!isIMAGE) {
        ErrorService.displayErrorAlert('Upload file can only be in picture format!');
        return false;
      }
      if (!isLt) {
        ErrorService.displayErrorAlert(`Upload file size cannot exceed ${this.fileSize} MB!`);
        return false;
      }
      return isIMAGE && isLt;
    },
    httpFunction(request) {
      this.handleUpload(request.file);
    },
  },
};
</script>
<style lang="scss" scoped>
.upload {
  position: relative;
  margin-top: 0.15rem;

  .step-title {
    @include steps-title();
    margin-bottom: 0.22rem;
  }

  &__container {
    width: 100%;
    margin-top: 0.1rem;
    height: 2.8rem;
  }

  .video-example {
    width: 100%;
    height: 100%;
  }

  .image-placeholder {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    img {
      width: 1.32rem;
    }
  }
  .drag-container {
    display: flex;
    flex-direction: column;
    width: 2.24rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    line-height: initial;

    img {
      width: 0.68rem;
      margin: 0.3rem 0 0.09rem;
    }

    h5 {
      font-weight: 500;
      font-size: 0.14rem;
      color: $text-gray;
      height: auto;
      line-height: 0.22rem;
    }
    button {
      border: 1px dashed $text-blue;
      color: $text-blue;
      background: $background-white;
      padding: 0.1rem 0.2rem;
      border-radius: 0.04rem;
      margin: 0.09rem 0 0.06rem;
      font-size: 0.14rem;
    }
    span {
      font-weight: 500;
      font-size: 0.1rem;
      margin-top: 0.04rem;
      color: $text-primary-dimmed;
    }
  }
  /deep/ .el-upload {
    &-list__item {
      background: $button-light-gray-bg;
      position: absolute;
      width: 100%;
      height: 2.8rem;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
    }
    &--picture-card {
      width: 100%;
      height: 100%;
      border: none;
    }
    &-dragger {
      width: 100%;
      height: 100%;
      background: $button-light-gray-bg;
      border: none;
    }
  }
  &.disabled {
    /deep/ .el-upload-dragger {
      cursor: default;
    }
  }
  .remove-button {
    position: absolute;
    top: 0.12rem;
    right: 0.12rem;
    font-size: 0.18rem;
    border-radius: 0.04rem;
    background: $background-white;
    cursor: pointer;
    width: 0.36rem;
    height: 0.36rem;
    text-align: center;
    color: $text-primary-dimmed;
  }

  .slot-file {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
  }
}
</style>
