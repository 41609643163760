<template>
  <div class="result-chart">
    <div
      v-for="item in chartData"
      :key="item.name"
      class="result-item"
    >
      <div class="description">
        <span class="name">{{ item.name }}</span>
        <span class="percentage">{{ `${item.percentage}%` }}</span>
      </div>
      <el-progress
        :percentage="item.percentage"
        :show-text="false"
        :stroke-width="12"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultChart',
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
  $progress-bg: #dbdfeb;

  .result-chart {
    .result-item {
      margin-top: 0.12rem;
      font-size: 0.14rem;
      line-height: 0.17rem;

      .description {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.07rem;
      }

      .name {
        font-weight: 600;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .percentage {
        font-weight: 400;
      }

      /deep/ .el-progress-bar__outer {
        background: $progress-bg;
        height: 0.12rem !important; // Overwriting inline style from element-ui
      }
    }
  }
</style>
